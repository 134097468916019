import React from 'react';
import Layout from "../../components/layout";
import InformationTable from "../../components/informationTable";
import Definition from "../../components/definition";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {Typography, Box} from "@material-ui/core";
import theme from '../../gatsby-theme-material-ui-top-layout/theme';
import Li from "../../components/li";
import { graphql } from 'gatsby';


export default function Part3() {
  const {t} = useTranslation();

  const columns = [
    "",
    `${t("part1Col1")} *`,
    `${t("Non-invasive prenatal testing (NIPT)")} ***`
  ]

  const rows = [
    [
      t("Time period in which the test is performed"),
      t("First blood test: between the 10th and 13th week of pregnancy. Second blood test: between the 14th and 16th week of pregnancy."),
      t("From the 10th week of pregnancy.")
    ],
    [
      <Definition word="detection">{t("Detection rate")}</Definition>,
      t("Approximately 85% of cases, when both samples are taken."),
      t("More than 99% of cases.")
    ],
    [
      <Definition word="false">{t("False positive rate")}</Definition>,  
      t("Indicates a false result in about 3% of cases."),
      t("Indicates a false result in less than 1% of cases.")
    ],
    [
      t("Waiting time for the result"),
      t("About 10 days after the second blood test."),
      t("About 5 to 10 days.")
    ],
    [
      t("Failure rate"), 
      t("No failure."),
      t("Failure in about 4% of cases.")
    ]
  ]

  return ( 
    <Layout pageTitle={t("What are the prenatal screening options?")} maxWidth={false} next="/screening/part2">
      <Box marginTop={theme.spacing(0.5)}>
        <Typography variant="body1" gutterBottom={true}>
            <strong>
              {t("Here are some factors to consider that may influence your choice of screening test.")}
            </strong>
            <br/>
            <br/>
          </Typography>
      </Box>
      <InformationTable rows={rows} columns={columns} />
      <Typography>
          {'* '}
          {t("If nuchal region")}
      </Typography>
      <Typography>
          {'** '}
          {t("Test at the right time")}
      </Typography>
      <Typography>
          {'*** '}
          {t("The NIPT is offered without fees by the Quebec Program as a first screening test in the following situations only:")}
      </Typography>
      <ol>
        <Li>{t("If, in a previous pregnancy, the baby had trisomy 21, trisomy 18, or trisomy 13.")}</Li>
        <Li>{t("If you are 40 years of age or older at the time of delivery.")}</Li>
        <Li>{t("If you have obtained a prescription following a consultation in genetic medicine.")}</Li>
        <Li>{t("If you are pregnant")}</Li>
      </ol>
      <Typography>
        {t("If you do not meet these conditions, and you want to do this test first, you will have to go to a private clinic (fee may apply).")}
      </Typography>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
